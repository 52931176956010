import React from "react";
import _ from "lodash";
import { graphql, Link } from "gatsby";
import tw, { styled } from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Icon from "@shared/components/Icon";
import Header from "@shared/components/Header";
import Table from "@shared/components/Table";
import { formatTeamName, HOME_TEAMS, MAIN_COLOR, SITE } from "../config";
import { useSiteFilter } from "../context/SiteFilterContext";
import TeamSelectionLarge from "../components/TeamSelectionLarge";

const Container = styled.div`
  ${tw`p-5`}
`;
const LinkContainer = styled.div`
  ${tw`grid grid-cols-3 sm:grid-cols-4 gap-4 my-6 px-5`}
`;
const StyledButton = styled.div`
  ${tw`
    w-full
    flex items-center justify-center 
    py-3 px-2 
    border border-gray-300 rounded
    bg-gray-200 whitespace-nowrap
    text-black hover:bg-gray-400
    transition-[background-color] duration-200
    cursor-pointer
  `}
`;

const TitleContainer = styled.h4`
  ${tw`
    bg-[#EC1F27] text-white font-bold flex justify-center
    p-2.5
  `}
`;

const RosterPage = ({ data, pageContext, location }) => {
  const { homeTeam: currentHomeTeam, setHomeTeam } = useSiteFilter();
  const { seo, hero, tabs, title } = data.markdownRemark.frontmatter;

  const allTeamPages = data.allTeams.edges;

  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        {/* {title && (
          <Header
            title={title}
            heroData={hero}
            tabsData={tabs}
            color={MAIN_COLOR}
            site={SITE}
          />
        )} */}
        <TitleContainer>
          All Teams
          {/* <TeamSelectionLarge location={location} /> */}
        </TitleContainer>
      </Container>
      <LinkContainer>
        {HOME_TEAMS.map((team, idx) => (
          <StyledButton onClick={() => setHomeTeam(team, location)} key={idx}>
            {formatTeamName(team)}
          </StyledButton>
        ))}
      </LinkContainer>
    </Layout>
  );
};

export default RosterPage;

export const pageQuery = graphql`
  query TeamListPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "team-list-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
        title
      }
    }
    allTeams: allMarkdownRemark(
      filter: {
        frontmatter: { mdType: { eq: "team" }, createPage: { eq: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            id
            name
          }
        }
      }
    }
  }
`;
